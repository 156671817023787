export const setData = payload => ({
	type: "SET_DATA", payload
});

export const setAccountCode = payload => ({
	type: "SET_ACCOUNT_CODE", payload
});

export const setInitialized = payload => ({
	type: "SET_INITIALIZED", payload
});

export const setAccessToken = payload => ({
	type: "SET_ACCESS_TOKEN", payload
});

export const setRefreshToken = payload => ({
	type: "SET_REFRESH_TOKEN", payload
});

export const setGroups = payload => ({
	type: "SET_GROUPS", payload
});