const blockOverflow = (block = true, blockClass = 'block-overflow') => {
	if (block) {
		let scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
		document.documentElement.style.marginRight = scrollBarWidth + 'px'
		document.body.classList.add(blockClass);
	}
	else {
		document.documentElement.style.marginRight = ''
		document.body.classList.remove(blockClass);
	}
}

export default blockOverflow