export const setMobile = payload => ({
	type: "SET_MOBILE", payload
});

export const setWindowWidth = payload => ({
	type: "SET_WINDOW_WIDTH", payload
});

export const setWindowHeight = payload => ({
	type: "SET_WINDOW_HEIGHT", payload
});

export const setPage = payload => ({
	type: "SET_PAGE", payload
});

export const setPageNotFound = payload => ({
	type: "SET_PAGE_NOT_FOUND", payload
});

export const setPageError = payload => ({
	type: "SET_PAGE_ERROR", payload
});

export const setRouteBase = payload => ({
	type: "SET_ROUTE_BASE", payload
});

export const setAnnouncements = payload => ({
	type: "SET_ANNOUNCEMENTS", payload
});

export const setCities = payload => ({
	type: "SET_CITIES", payload
});

export const setTheme = payload => ({
	type: "SET_THEME", payload
});