// Deps
import request from 'utils/request'
import store from 'store'
//import extend from 'lodash/extend'

// Actions
import { setData, setInitialized, setRefreshToken, setAccessToken, setAccountCode, setGroups } from 'store/user/actions'
import { setTheme } from 'store/site/actions'

// Functions
import isExact from 'functions/is-exact'

// Services
import companyServices from 'services/company'
import commerceServices from 'services/commerce'

const userServices = {
	me: () => {
		return new Promise((resolve, reject) => {
			let userState = store.getState()['user'];
			if(userState.accessToken) {
				request.post('get-me', false, (payload, status, request) => {
					if(status === 200 && payload) {

						userServices.updateUserGroups();
						userServices.setUserData(payload);
						resolve(payload);

					}
					else {
						userServices.setUserData(false);
						reject(request);
					}
				}, { exceptionOnError: true, showMessages: false });
			}
			else {
				userServices.setUserData(false);
				reject(false);
			}
		});
	},
	login: (formData) => {
		return new Promise((resolve, reject) => {
			request.post('login', formData, (payload, status, request) => {
				if(status === 200 && payload && payload.access_token && payload.refresh_token) {
					userServices.setAccessToken(payload.access_token);
					userServices.setRefreshToken(payload.refresh_token);
					userServices.me().then((mePayload) => {
						resolve(mePayload);
					}).catch(() => {
						userServices.setAccessToken(false);
						userServices.setRefreshToken(false);
						reject(payload.fail_count);
					})
				}
				else {
					userServices.setUserData(false);
					reject(payload.fail_count);
				}
			});
		});
	},
	registerCompany: (formData) => {
		return new Promise((resolve, reject) => {
			request.post('register-company', formData, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// register: (userData) => {
	// 	return new Promise((resolve, reject) => {
	// 		request.post('users/register', userData, (payload, status, request) => {
	// 			if(status === 200 && payload && payload.token) {
	// 				resolve(payload.token);
	// 			}
	// 			else {
	// 				reject(request);
	// 			}
	// 		});
	// 	});
	// },
	// Helpers
	logout: () => {
		userServices.setUserData(false);
		userServices.setAccessToken(false);
		userServices.setRefreshToken(false);
		commerceServices.clearBasket();
	},
	updateUserGroups: () => {
		companyServices.listGroups({ per_page: 9999 }).then((groupsPayload) => {
			store.dispatch(setGroups(groupsPayload.groups));
		}).catch((error) => {
			console.warn('Fetching groups failed.', error);
		});
	},
	setUserData: (newData, overlap) => {
		const curState = store.getState();

		if(newData && overlap) {
			newData = { ...store.getState()['user'].data, ...newData };
		}

		if(newData === false) {
			localStorage.removeItem('userData');
			// userServices.setTheme(false);
		}
		else {
			if(newData.company && newData.company.product_delivery_methods) {
				let methods = [];

				try {
					methods = JSON.parse(newData.company.product_delivery_methods)
				}
				catch(e) {}
				newData.company.deliveryMethods = methods;
			}
			localStorage.setItem('userData', JSON.stringify(newData));
			// userServices.setTheme((newData.company && newData.company.theme_id) ? newData.company.theme_id : '1');
		}
		if(!isExact(newData, curState.user.data)){
			store.dispatch(setData(newData));
		}
		if(curState.user.accountCode !== newData.company_account_code) {
			store.dispatch(setAccountCode(newData.company_account_code));
		}
		if(!curState.user.initialized) {
			store.dispatch(setInitialized(true));
		}
	},
	setAccessToken: (token) => {
		if(token === false) {
			localStorage.removeItem('accessToken');
		}
		else {
			localStorage.setItem('accessToken', token);
		}
		store.dispatch(setAccessToken(token));
	},
	setRefreshToken: (token) => {
		if(token === false) {
			localStorage.removeItem('refreshToken');
		}
		else {
			localStorage.setItem('refreshToken', token);
		}
		store.dispatch(setRefreshToken(token));
	},
	setTheme: (theme) => {
		if(theme) {
			if(store) {
				store.dispatch(setTheme(theme));
			}
			document.body.setAttribute('class', 'theme-' + theme);
		}
		else {
			if(store) {
				store.dispatch(setTheme(1));
			}
			document.body.setAttribute('class', '');
		}
	},
}

//console.trace('geliyor', userServices);

export default userServices;