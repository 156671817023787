import React, { useState, useEffect, useRef } from 'react'

// Deps
import parse from 'html-react-parser'

const useDisplayStatus = (loading) => {
	const loadingTimeout = useRef(false);
	const _mounted = useRef(false);

	const [active, setActive] = useState(loading !== false);
	const [show, setShow] = useState(loading !== false);

	useEffect(() => {
		_mounted.current = true;
		
		return(() => {
			_mounted.current = false;
		});
	}, [])

	useEffect(() => {
		if(loadingTimeout.current !== false){
			clearTimeout(loadingTimeout.current);
			loadingTimeout.current = false;
		}

		if(loading !== false){
			setActive(true);
			loadingTimeout.current = setTimeout(function() {
				if(_mounted.current) {
					setShow(true);
				}
			}, 30);
		}
		else {
			setShow(false);
			loadingTimeout.current = setTimeout(function() {
				if(_mounted.current) {
					setActive(false);
				}
			}, 200);
		}
	}, [loading]);

	return [active, show];
}

const Loader = (props) => {
	const container = useRef(false);

	const [active, show] = useDisplayStatus(props.loading);

	let classes = 
		'loading-indicator' +
		(props.className ? ' ' + props.className : '') +
		(props.strict ? ' strict' : '') +
		(props.inner ? ' inner' : '') +
		(props.large ? ' large' : '') +
		(props.dark ? ' dark' : '') +
		(props.error ? ' error' : '') +
		(active ? ' active' : '') +
		(show ? ' show' : '');

	return active ? (
		<div
			className={classes}
			ref={container}>
			<div className="indicator-wrapper">
				{props.message &&
					<div className="indicator-message">{(typeof props.message === 'string' ? parse(props.message) : props.message)}</div>
				}
				<i className="icon-spinner"></i>
			</div>
		</div>
	) : false;
}

Loader.defaultProps = {
	loading: false,
	className: '',
	message: false,
};

export default Loader;