import React from 'react'

// Partials
import Btn from 'views/shared/partials/btn'

// Deps
//import { closeModal } from 'utils/modals'
import parse from 'html-react-parser'
import extend from 'lodash/extend'
import omit from 'lodash/omit'

// Assets

export default class OptionsModal extends React.Component {
	constructor(props) {
		super(props);
		this.optSelected = false;
		
		this.optionClick = this.optionClick.bind(this);
	}

	optionClick(opt) {
		if(!this.optSelected) {
			this.optSelected = true;
			if(opt.onClick){
				opt.onClick();
				if(opt.closeOnClick){
					this.props.close();
				}
			}
			else {
				this.props.close();
			}
		}
	}

	render() {
		let vm = this;
		return (
			<div className={vm.props.className}>
				<div className="modal-content">
					{vm.props.title && 
						<h1 className="options-title">{vm.props.title}</h1>
					}{vm.props.message && 
						<div className={"options-message" + (vm.props.wysiwyg ? ' wysiwyg' : '')}>{parse(vm.props.message.replace(/(?:\r\n|\r|\n)/g, '<br />'))}</div>
					}

					<div className="options-opts">
					{vm.props.opts.map((opt, nth) => {
						let optDefaults = {
							onClick: false,
							className: '',
							closeOnClick: true,
						}
						opt = extend({}, optDefaults, opt);

						let optProps = omit(opt, ['onclick', 'className', 'closeOnClick', 'text']);
						return (
							<Btn
								key={nth}
								className={'opts-item ' + opt.className}
								{...optProps}
								onClick={() => {vm.optionClick(opt)}}>
								{opt.text}
							</Btn>
						)
					})}
					</div>
				</div>
			</div>
		)
	}
}

OptionsModal.defaultProps = {
	className: "",
	containerClass: "modal-options narrow",
	name: "options",
	preventClose: true,
	title: false,
	message: false,
	wysiwyg: true,
	opts: []
}