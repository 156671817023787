import store from 'store'
import {
	openModal as modalOpener,
	closeModal as modalCloser,
	openTopModal as topModalOpener,
	closeTopModal as topModalCloser,
} from 'store/modals/actions'
//import omit from 'lodash/omit'

export function openModal(key, opts = {}) {
	//console.trace('openmodal');
	let payload = { modal: key, ...opts, timestamp: new Date().getTime() };

	if(opts.top === true) {
		store.dispatch(topModalOpener(payload));	
	}
	else {
		store.dispatch(modalOpener(payload));
	}
}

export function closeModal(topModal = null) {
	//console.trace('close modal');
	if(topModal === true) {
		store.dispatch(topModalCloser());
	}
	else if(topModal === false) {
		store.dispatch(modalCloser());
	}
	else {
		store.dispatch(topModalCloser());
		store.dispatch(modalCloser());
	}
}