import { combineReducers, createStore } from 'redux'
import siteReducer from 'store/site';
import userTerminalReducer from 'store/user';
import messengerReducer from 'store/messenger';
import modalsReducer from 'store/modals';
import commerceReducer from 'store/commerce';
import translationReducer from 'store/translation';

const rootReducer = combineReducers({
	'site': siteReducer,
	'user': userTerminalReducer,
	'messenger': messengerReducer,
	'modals': modalsReducer,
	'commerce': commerceReducer,
	'translation': translationReducer,
})

const store = createStore(rootReducer);

export default store;