const uid = (prefix = 'uid') => {
	if (!window._uidList) {
		window._uidList = [];
	}
	let id = getid(prefix);

	return id;

	function getid(prefix) {
		let id = (prefix ? (prefix + '_') : '') + Math.random().toString(36).substr(2, 9);

		if (window._uidList.includes(id)) {
			id = getid(prefix);
		}
		return id;
	}
}
export default uid