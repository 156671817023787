const activeLanguage = window.localStorage.getItem('activeLanguage');

const initialState = {
	activeLanguage: activeLanguage ? activeLanguage : 'tr',
	index: false,
};

function translationReducer(state = initialState, action) {
	if (action.type === "SET_ACTIVE_LANGUAGE") {
		return {
			...state,
			activeLanguage: action.payload,
		};
	}
	else if (action.type === "SET_INDEX") {
		return {
			...state,
			index: (state.index ? {...state.index, ...action.payload} : action.payload)
		};
	}
	return state;
};
export default translationReducer;