import React from 'react'

// Deps
import { connect } from 'react-redux'
import isEqual from "lodash/isEqual"
import extend from "lodash/extend"
import store from "store";
import uid from "functions/uid";
import { addMessage, clearMessages } from "store/messenger/actions"

const mapStateToProps = state => {
	return {
		messages: state.messenger.messages,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		clearMessages: () => dispatch(clearMessages())
	}
}

class MessengerWrap extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			messages: props.messages
		}

		this.removeMessage = this.removeMessage.bind(this);
	}

	componentDidUpdate(prevProps){
		if(!isEqual(prevProps.messages, this.props.messages) && this.props.messages.length){
			this.setState({messages: [...this.props.messages, ...this.state.messages]});
			this.props.clearMessages();
		}
	}

	removeMessage(id){
		this.setState({messages: this.state.messages.filter((msg) => {
			return (msg.id === id ? false : true);
		})});
	}

	render() {
		if(this.state.messages.length){
			return (
				<div className="messenger-container">
					{this.state.messages.map((message, nth) => (
						<MessengerItem
							message={message}
							onRemove={this.removeMessage}
							key={message.id} />
					))}
				</div>
			)
		}
		else return false;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MessengerWrap);

class MessengerItem extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
		}

		this._mounted = false;
		this._hiding = false;
		this.hideMessage = this.hideMessage.bind(this);
	}

	componentDidMount() {
		let vm = this;
		vm._mounted = true;

		setTimeout(function() {
			if(vm._mounted) {
				vm.setState({show: true});
			}
		}, 10);

		setTimeout(function() {
			if(vm._mounted) {
				vm.hideMessage();
			}
		}, vm.props.message.delay);
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	hideMessage() {
		let vm = this;
		if(!vm.hiding) {
			vm._hiding = true;
			vm.setState({show: false});
			setTimeout(function() {
				if(vm._mounted) {
					vm.props.onRemove(vm.props.message.id);
				}
			}, 401);
		}
	}

	render() {
		let msg = this.props.message
		return (
			<button
				onClick={this.hideMessage}
				className={"messenger-message " + msg.type + (this.state.show ? ' show' : '')}>
					{msg.message}
				</button>
		)
	}
}

export function pushMessage(message = "", opts = {}){
	let defaultOpts = {
		id: uid('messenger'),
		type: "success",
		delay: 3000,
		message: message,
	}

	let data = extend({}, defaultOpts, opts);

	store.dispatch(addMessage(data));

}