import React, { useState, useEffect, useRef } from 'react'

// Deps
import { connect } from 'react-redux';
import store from "store";
import { setPage, setPageNotFound, setPageError } from "store/site/actions";
import { matchPath/*, Redirect*/ } from 'react-router-dom'
import config from 'config'
import userServices from 'services/user';

// Functions
import uid from 'functions/uid'
import isExact from 'functions/is-exact'

// Controllers
import Messenger from 'controllers/messenger'
import ModalController from 'controllers/modal-controller'

// Utils
import history from 'utils/history'
import { setTitle, setMeta, setHead, setDescription, clearHead } from 'utils/head'
import { getLanguageData } from 'utils/translation'

// Views
import Loader from 'views/shared/pages/loader'

// Routes
import terminalRoutes from 'routes/terminal'
import paymentRoutes from 'routes/payment'
import adminRoutes from 'routes/admin'

const routeBaseRegistry = {
	terminal: terminalRoutes,
	payment: paymentRoutes,
	admin: adminRoutes,
}

const mapStateToProps = (state, ownProps) => {
	return {
		currentPage: state.site.currentPage,
		pageNotFound: state.site.pageNotFound,
		pageError: state.site.pageError,
		//userData: state['user-' + ownProps.routeBase].data, // çoklu authentication gerektiğinde
		userInitialized: state.user.initialized,
		userData: state.user.data,
		routeBase: state.site.routeBase,
		translationIndex: state.translation.index,
		activeLanguage: state.translation.activeLanguage,
	};
};

class Navigator extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentRoute: history.location,
		}

		this._mounted = false;

		changePage();
		initializeApp(props);
	}

	componentDidMount() {
		window.dynamicHistory = history;
		let vm = this;
		vm._mounted = true;

		history.listen((e) => {
			if(vm._mounted) {
				window.prerenderReady = false;
				
				store.dispatch(setPageNotFound(false));
				store.dispatch(setPageError(false));

				if (e.hash !== (vm.state.currentRoute ? vm.state.currentRoute.hash : false)) {
					let hashEvent = new Event('hash-change');
					window.dispatchEvent(hashEvent);
				}
				vm.setState({ currentRoute: e });
				//let route = getRouteFromUrl(e.pathname, false, true);
				changePage();
			}
		});
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.currentPage.data.requiresLogin === true &&
			(
				this.props.userData === false ||
				(this.props.userData === false && !this.props.userData.company_account_code)
			)
		) {
			redirect('login');
		}
		else if (
			this.props.currentPage.data.requiresOwnership === true &&
			(
				this.props.userData === false ||
				(this.props.userData && !this.props.userData.is_owner)
			)
		) {
			redirect('home');
		}
		else if (
			this.props.userData && this.props.userData.is_terminal &&
			this.props.routeBase === 'admin'
		) {
			redirect({to: 'dashboard', replace: true, base: 'terminal'});
		}
		else if (
			(prevProps.activeLanguage !== this.props.activeLanguage && this.props.currentPage.multiLanguage === true) ||
			(prevProps.currentPage.key !== this.props.currentPage.key && this.props.currentPage.data.multiLanguage === true && !this.props.translationIndex) 
		) {
			getLanguageData(this.props.activeLanguage);
		}

		// if(prevProps.routeBase !== this.props.routeBase) {
		// 	if (prevProps.routeBase === 'terminal' && window.document.fullscreen) {
		// 		if (window.document.exitFullscreen) {
		// 			window.document.exitFullscreen().then(() => {
		// 			}).catch(() => {
		// 			})
		// 		}
		// 	}
		// 	else if (this.props.routeBase === 'terminal' && !window.document.fullscreen) {
		// 		window.document.documentElement.requestFullscreen().then(() => {
		// 		}).catch(() => {
		// 		})
		// 	}
		// }
	}
	
	render() {
		if ((this.props.userData === null || (this.props.userData && !this.props.userInitialized) || (this.props.currentPage.multiLanguage && this.props.translationIndex === false)) && !this.props.pageNotFound && !this.props.pageError) {
			return (
				<Loader routeBase={this.props.routeBase} />
			)
		}
		else if(!(this.props.userData === false && this.props.currentPage.data.requiresLogin !== false) && this.props.currentPage.key) {
			return (
				<PageRenderer
					currentPage={this.props.currentPage}
					modals={this.props.modals.type.toString() === 'Symbol(react.fragment)' ? this.props.modals.props.children : this.props.modals}
					location={this.props.location}
					pageRegistry={this.props.pageRegistry} />
			)
		}
		else { return false; }
	}
}

const getRoutes = (base = false) => {
	return routeBaseRegistry[base ? base : store.getState().site.routeBase];
}

const initializeSession = () => {
	if(window.sessionStorage.getItem('session_id') === null) {
		window.sessionStorage.setItem('session_id', uid(false));
	}
}

const initializeApp = (props) => {
	initializeSession();
	userServices.me().then(() => {}).catch(() => {});

	if (props.location.search) {
		let search = props.location.search.replace('?', '').split('&');
		let newSearch = [...search];

		for (const s of search) {
			const [key, val] = s.split('=');
			if (key === 'language') {
				if(val !== props.activeLanguage) {
					getLanguageData(val);
				}
				newSearch.splice(newSearch.indexOf(s), 1);
			}
		}

		if(!isExact(newSearch, search)){
			history.replace(props.location.pathname + newSearch.length ? '?' + newSearch.join('&') : '');
		}

	}
}

const useRoutes = () => {
	let list = [];

	const routes = getRoutes();

	for(let groupKey in routes){
		for(let routeKey in routes[groupKey]){
			let route = routes[groupKey][routeKey];

			list.push({...route, key: routeKey, group: groupKey, fullKey: groupKey+'.'+routeKey});
		}
	}

	return list;
}

const PageRenderer = (props) => {
	let routeList = useRoutes();

	return (
		<React.Fragment>
			<Messenger />
			<ModalController>
				{props.modals}
			</ModalController>
			{routeList.map((route) => {
				let active = route.fullKey === props.currentPage.fullKey
				return (<PageWrap
					key={route.fullKey}
					route={route}
					pageRegistry={props.pageRegistry}
					location={props.location}
					match={active ? props.currentPage.data.match : false}
					active={active} />)
			})}
		</React.Fragment>
	);
}

const PageWrap = (props) => {
	const _mounted = useRef(false);

	const [active, setActive] = useState(props.active);
	const [show, setShow] = useState(props.active);
	const [match, setMatch] = useState(props.match);

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, [])

	useEffect(() => {
		if(props.match !== false) {
			setMatch(props.match);
		}
	}, [props.match])

	useEffect(() => {
		if(props.active && !active) {
			if(config.pageTransition) {
				setTimeout(function() {
					if (_mounted.current) {
						setActive(true);

						setTimeout(function() {
							if (_mounted.current) {
								setShow(true);
							}
						}, 50 /*config.pageTransition*/);
					}
				}, config.pageTransition);
			}
			else{
				setActive(true);
				setShow(true);
			}
		}
		else if(!props.active && active) {
			setShow(false);

			if(config.pageTransition) {
				setTimeout(function() {
					if (_mounted.current) {
						setActive(false);
					}
				}, config.pageTransition);
			}
			else{
				setActive(false);
			}
		}
	}, [props.active, active])

	if(active) {
		const Component = props.pageRegistry[props.route.component];
		if(Component) {
			return <Component show={show} location={props.location} match={{...match, route: props.route}} />;
		}
		else { console.warn('Navigator error: Component not found: ' + props.route.component); return false; }
	}
	else {
		return false;
	}
}

export const changePage = (key = false, group = 'pages', opts = {}) => {
	const routes = getRoutes();
	let route = (key ? routes[group][key] : getRouteFromUrl(false, true));

	if (route.key) {
		key = route.key;
		group = route.groupKey;
	}

	if (key === 'notFound') {
		store.dispatch(setPageNotFound(true));
	}

	if (key === 'error') {
		store.dispatch(setPageError(true));
	}

	let pageData = {
		key: key,
		group: group,
		fullKey: group + "." + key,
		data: route,
	}

	const prevPage = store.getState().site.currentPage;

	if (
		prevPage.fullKey !== group + '.' + key ||
		(route.match && prevPage.data.match && !isExact(route.match.params, prevPage.data.match.params)) ||
		(route.match && !prevPage.data.match) ||
		(!route.match && prevPage.data.match)
	) {
		if(config.pageTransition) {
			setTimeout(function() {
				window.scroll(0, 0);
			}, config.pageTransition);
		}
		else {
			window.scroll(0, 0);
		}
		store.dispatch(setPage(pageData));

		// let hash = window.location.hash;

		// if (hash) {
		// 	setTimeout(function () {
		// 		let hashTarget = document.querySelector(hash)
		// 		if (hashTarget) {
		// 			hashTarget.scrollIntoView();
		// 		}
		// 	}, 500);
		// }
	}

	setTitle(route.title);
	if (route.description) {
		setDescription(route.description);
	}
	
	// Clear Head
	clearHead('http');
	setMeta((route.meta ? route.meta : false), opts.keepHead !== true);
	setHead((route.head ? route.head : false), opts.keepHead !== true);

	setHead([
		{
			key: "link",
			props: {
				rel: "canonical",
				href: window.location.href,
			}
		},
		{
			key: "meta",
			props: {
				property: "og:url",
				content: window.location.href,
			}
		}
	]);

	let status = (opts.statusCode ? opts.statusCode : (route.status ? route.status : 200));

	setHead([
		{
			key: "meta",
			props: {
				name: "prerender-status-code",
				content: status,
			}
		}
	], false, 'http');

	if(status === 301) {
		setHead([
			{
				key: "meta",
				props: {
					name: "prerender-header",
					content: window.location.href,
				}
			}
		], false, 'http');
	}

	if(route.autoReadyHead !== false) {
		setTimeout(function() {
			window.prerenderReady = true;
		}, 10);
	}
}

export const getRoute = (key = false, group = 'pages', base = false) => {
	const routes = getRoutes(base);

	let routeGroup = group;
	let keyParts = key.split('.');
	if (keyParts.length === 2) {
		routeGroup = keyParts[0];
		key = keyParts[1];
	}

	if(routes[routeGroup]) {
		let target = routes[routeGroup][key];
		return (target ? target : false);
	}
	else {
		return false;
	}
}

export const getRouteFromUrl = (url = false, includeCatch = false, getObject = true) => {
	const routes = getRoutes();
	if (url === false) { url = window.location.pathname.replace(/\/$/, ''); }
	let returnRoute = false;
	let catchRoute = false;
	Object.keys(routes).forEach((groupKey, index) => {
		let group = routes[groupKey];

		Object.keys(group).forEach((key, index) => {
			let route = routes[groupKey][key];
			if (route.path) {
				let match = matchPath(url, route.path);
				if (match && match.isExact) {
					returnRoute = (getObject ? {...route, key: key, groupKey: groupKey, match: match} : [key, groupKey]);
				}
			}
			else if (includeCatch && catchRoute === false) {
				catchRoute = (getObject ? {...route, key: key, groupKey: groupKey} : [key, groupKey]);
			}
		});
	});

	return (returnRoute ? returnRoute : catchRoute);
}

export const redirect = (opts, params = false, getParams = false) => {
	const defaultOpts = {
		type: 'push',
		base: false,
	}

	opts = (Object.prototype.toString.call(opts) === "[object String]" ? {...defaultOpts, to: opts} : {...defaultOpts, ...opts});

	let route = getRoute(opts.to, undefined, opts.base).path;

	if (route) {
		if (params) {
			for (let k = 0; k < Object.keys(params).length; k++) {
				let key = Object.keys(params)[k];
				route = route.replace(':' + key + '?', params[key]).replace(':' + key, params[key]);
			}
		}

		let getString = "";
		if (getParams) {
			for (let p = 0; p < Object.keys(getParams).length; p++) {
				let key = Object.keys(getParams)[p];

				if (getString !== "") {
					getString += "&";
				}
				getString += key + "=" + encodeURIComponent(getParams[key]);
			}
		}

		route = route.split('/:')[0];

		if (getString !== "") { route = route + "?" + getString }
		switch (opts.type) {
			case "replace":
				history.replace(route);
				break;
			default:
				history.push(route);
				break;
		}
		changePage();
		return true;
	}
	else {
		return false;
	}
}

export function set404() {
	changePage('notFound');
}

export function setError() {
	changePage('error');
}

export default connect(mapStateToProps)(Navigator);