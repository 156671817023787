// Deps
import axios from 'axios';
import { pushMessage } from 'controllers/messenger'
import { setError } from 'controllers/navigator'
//import extend from 'lodash/extend'
import config from 'config'
import store from 'store'
import objectToQuery from 'functions/object-to-query'
import { setDescription, setHead, setTitle } from 'utils/head'
// import { logError } from 'utils/errors'

// const defaultConfig = {
// 	excludeApiPath: false,
// };

import userServices from 'services/user'

axios.defaults.headers.post['Content-Type'] = undefined;

export default {
	get: function (target, data = false, finalFunction = false, opts = {}) {
		return makeRequest('get', target, data, finalFunction, opts);
	},
	post: function (target, data = false, finalFunction = false, opts = {}) {
		var method = opts.dummy ? 'get' : 'post';
		return makeRequest(method, target, data, finalFunction, opts);
	},
	put: function (target, data = false, finalFunction = false, opts = {}) {
		var method = opts.dummy ? 'get' : 'put';
		return makeRequest(method, target, data, finalFunction, opts);
	},
	delete: function (target, data = false, finalFunction = false, opts = {}) {
		var method = opts.dummy ? 'get' : 'delete';
		return makeRequest(method, target, data, finalFunction, opts);
	}
}

function makeRequest(method, target, data = false, finalFunction = false, opts = {}) {
	// if(config.dummyApi && method === 'post') {
	// 	method = 'get'
	// }
	if (data === false) { data = {}; }
	let config = {
		dummy: false,
		exceptionOnError: false,
		showMessages: null,
		external: false,
		excludeApiPath: false,
		target: target,
		method: method,
		...opts
	};

	if(config.showMessages === null) {
		// config.showMessages = config.exceptionOnError ? [
		// 	'success'
		// ] : [
		// 	'error',
		// 	'success',
		// 	'warning'
		// ]

		config.showMessages = [
			'error',
			'success',
			'warning'
		];
	}

	let ajaxToken = axios.CancelToken;
	let ajaxController = ajaxToken.source();

	if (!config.excludeApiPath && !config.external) {
		config.target = apiPath(target, config.dummy ? 'dummy' : false);
	}

	let ajaxOpts = {
		method: config.method,
		url: config.target,
		cancelToken: ajaxController.token,
		headers: headData(config.external)
	};

	if(config.method === 'get' && data) {
		let dataQuery = objectToQuery(data);
		if(dataQuery.length) {
			ajaxOpts.url += '?' + dataQuery;
		}
	}
	else {
		ajaxOpts.data = data;
	}

	config.data = data;
	config.headers = ajaxOpts.headers;

	axios(ajaxOpts).then(res => {
		evaluateData(res, finalFunction, config);
	}).catch(error => {
		evaluateData(error.response ? error.response : {status: 500, data: {}}, finalFunction, config)
	});

	return ajaxController;
}

function evaluateData(response, finalFunction = false, config) {
	if([500, 429].includes(response.status) && config.exceptionOnError) {
		// logError('requestError', 'Unexpected Request Error', {status: response.status, url: config.target, method: config.method, headers: config.headers, data: config.data});
		setError();
	}
	else {
		let hasMessage = false;

		if(!config.external){
			if(response.data.messages && Object.keys(response.data.messages).length){
				if (config.showMessages && config.showMessages.includes(response.data.status)){
					for(const index in response.data.messages){
						pushMessage(response.data.messages[index], { type: response.data.status});
						hasMessage = true;
					}
				};
			}
			// Validation Hataları
			else if (response.data.errors && Object.keys(response.data.errors).length) {
				if (config.showMessages && config.showMessages.includes('error')) {
					for(const index in response.data.errors){
						response.data.errors[index].forEach(message => {
							pushMessage(message, { type: 'error' });
						})
					}
					hasMessage = true;
				}
			}

			if(response.data.head && response.data.head.title) {
				setTitle(response.data.head.title);

				if(response.data.head.description) {
					setDescription(response.data.head.description);
				}

				if(response.data.head.image) {
					setHead([{
						key: "meta",
						props: {
							property: "og:image",
							content: response.data.head.image,
						}
					}]);
				}
			}
		}

		switch (response.status) {
			case 200:
			case 201:
				if (finalFunction) {
					finalFunction((config.external ? response.data : response.data.data), response.status, response);
				}
			break;
			case 401:
				pushMessage("Hesabınızdan çıkış yapıldı.", { type: "error" });
				userServices.logout();
				if (finalFunction) {
					finalFunction(false, response.status, response);
				}
			break;
			default:
				if(!config.external && !hasMessage && config.showMessages && config.showMessages.includes('error')) {
					pushMessage("Hata: İşlem başarısız.", { type: "error" });
				}

				if (finalFunction) {
					finalFunction(false, response.status, response);
				}
			break;
		}
	}
}

function apiPath(target, type = false) {
	let path = ''
	switch(type) {
		case 'dummy':
			path = ('/dummy/api/' + target + '.json');
		break;
		case 'external':
			path = target;
		break;
		default:
			path = (config.apiURL + target);
		break;
	}

	return path;
}

function headData(external = false) {
	const curState = store.getState();

	return external ?
	{
		'Content-Type': 'application/json',
	}
	:
	{
		'Content-Type': 'application/json',
		//'Content-Type': 'text/html; charset=UTF-8',
		'Accept': 'application/json',
		//'Access-token': curState['user-' + curState.site.routeBase].accessToken, // çoklu authentication gerektiğinde
		'Access-token': curState.user.accessToken,
		// 'language': curState.translation.activeLanguage,
		// 'Guest-Token' : curState['user-' + curState.site.routeBase].guestToken,
		// 'Authorization': 'Bearer ' + curState['user-' + curState.site.routeBase].authToken,
	}
}