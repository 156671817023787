import { getMobileState } from 'controllers/dom-watcher'

// Data
import themes from 'data/themes'

const computeTheme = () => {
	if(localStorage.getItem('userData') !== null){
		try {
			const storageData = JSON.parse(localStorage.getItem('userData'));
			if(storageData && storageData.company && storageData.company.theme_id) {
				let theme = themes.find(t => t.value.toString() === storageData.company.theme_id.toString());
				if(!theme) {
					theme = themes[0];
				}
				return theme;
			}
		}
		catch{
			return themes[0];
		}
	}
	else {
		return themes[0];
	}
}

const initialState = {
	currentPage: {
		key: false,
		data: {}
	},
	mobile: getMobileState(),
	windowWidth: window.outerWidth,
	windowHeight: window.outerHeight,
	pageNotFound: false,
	pageError: false,
	routeBase: false,
	annoucnements: false,
	cities: false,
	theme: computeTheme(),
};

const siteReducer = (state = initialState, action) => {
	if (action.type === "SET_MOBILE") {
		return {
			...state,
			mobile: action.payload
		};
	}
	else if (action.type === "SET_WINDOW_WIDTH") {
		return {
			...state,
			windowWidth: action.payload
		};
	}
	else if (action.type === "SET_WINDOW_HEIGHT") {
		return {
			...state,
			windowHeight: action.payload
		};
	}
	else if (action.type === "SET_PAGE") {
		return {
			...state,
			currentPage: action.payload
		};
	}
	else if (action.type === "SET_PAGE_NOT_FOUND") {
		return {
			...state,
			pageNotFound: action.payload
		};
	}
	else if (action.type === "SET_PAGE_ERROR") {
		return {
			...state,
			pageError: action.payload
		};
	}
	else if (action.type === "SET_ROUTE_BASE") {
		return {
			...state,
			routeBase: action.payload
		};
	}
	else if (action.type === "SET_ANNOUNCEMENTS") {
		return {
			...state,
			announcements: action.payload
		};
	}
	else if (action.type === "SET_CITIES") {
		return {
			...state,
			cities: action.payload
		};
	}
	else if (action.type === "SET_THEME") {
		let theme = themes.find(t => t.value.toString() === action.payload.toString());
		if(!theme) { theme = themes[0]; }
		return {
			...state,
			theme: theme,
		};
	}
	return state;
};

export default siteReducer;