// Deps
import parse from 'html-react-parser'
import nlToBr from 'functions/nl-to-br'
//import $ from 'zepto';

const processHTMLData = (content) => {
	// let $content = $('<div>' + content.replace(/\n|\r\n|\r|\t/g, '') + '<div>');
	// $('a', $content).attr('target', '_blank').attr('rel', 'noopener');
	// return parse($content.html());

	let container = document.createElement('div');
	container.innerHTML = nlToBr(content).replace(/\n|\r\n|\r|\t/g, '');

	// Test
	// for(let link of container.querySelectorAll('a')) {
	// }

	return parse(container.innerHTML);
}

const parseHTML = (data, processData = true) => {
	if(processData) {
		return processHTMLData(data);
	}
	else {
		return parse(data);
	}
}

export default parseHTML