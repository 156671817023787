const themes = [
	{
		value: 1,
		label: 'Vanilya',
	},
	{
		value: 2,
		label: 'Hardal Sarısı',
	},
	{
		value: 3,
		label: 'Derin Mavi',
		footer_lightLogo: true,
	},
]

export default themes