// Services
import commerceServices from 'services/commerce'

const initialState = {
	basketContent: commerceServices.parseBasket(),
};

const siteReducer = (state = initialState, action) => {
	if (action.type === "SET_BASKET_CONTENT") {
		return {
			...state,
			basketContent: action.payload
		};
	}
	// else if (action.type === "SET_INDEXED_BASKET_CONTENT") {
	// 	return {
	// 		...state,
	// 		indexedBasketContent: action.payload
	// 	};
	// }
	return state;
};

export default siteReducer;