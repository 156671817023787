// Config
import devConfig from 'config/env/dev'
import prodConfig from 'config/env/prod'
import pstvConfig from 'config/env/pstv'

// Functions
import getCompanyConfig from 'functions/get-company-config'

let env = process.env.REACT_APP_CONFIG_ENV ? process.env.REACT_APP_CONFIG_ENV : process.env.NODE_ENV;

const defaultConfig = {
	environment: env,
	version: `${env}_${(process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : "unspecified")}`,
	titlePostFix: 'Ödedim',
	mobileBreakPoint: 960,
	pageTransition: false,
	sentryDSN: 'http://edeae1cc75df40c5aa3ff1d8691d94e8@164.92.215.182:9000/12',
	captchaSiteKey: false,
	currencyDisplayMode: 'symbol',
	currencyDisplayPosition: 'start',
	maps: {
		apiKey: 'AIzaSyBLtvYX-eRxmXo4s_Dk7tNpk-TVQTF-j9c',
	},
	defaultAnalyticsCode: `
		<script async src="https://www.googletagmanager.com/gtag/js?id=G-PEJ2PBF8XR"></script>
		<script>
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', 'G-PEJ2PBF8XR');
		</script>
	`,
}

let config;

switch(env) {
	case "production":
		config = {...defaultConfig, ...prodConfig};
	break;
	case "pstv":
		config = {...defaultConfig, ...pstvConfig};
	break;
	default:
		config = {...defaultConfig, ...devConfig};
	break;
}

const { baseConfig, ...companyConfig } = getCompanyConfig();

config = {
	...config,
	...(baseConfig ? baseConfig : {}),
	company: companyConfig,
}

if (process.env.REACT_APP_API_URL) {
	config.apiURL = process.env.REACT_APP_API_URL;
}

if(env !== 'production') {
	window.dumpConfig = () => {
		console.log('Environment: ', env);
		console.log(config);
	}
}

export default config;
