// Deps
import request from 'utils/request'
import store from 'store'

// Actions
import { setAnnouncements } from 'store/site/actions'

const companyServices = {
	// Company
	getCompany: () => {
		return new Promise((resolve, reject) => {
			request.post('get-company', false, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			}, { exceptionOnError: true });
			
		});
	},
	getCompanyForPayment: (subURL) => {
		return new Promise((resolve, reject) => {
			request.post('get-company-for-pay', { company_sub_url: subURL }, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			}, { exceptionOnError: true });
			
		});
	},
	updateCompany: (newCompanyData) => {
		return new Promise((resolve, reject) => {
			request.post('update-company', newCompanyData, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			}, { exceptionOnError: true });
			
		});
	},
	// Client
	getClient: (clientCode) => {
		return new Promise((resolve, reject) => {
			request.post('get-client', {client_code: clientCode}, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
			
		});
	},
	getERPClient: (clientCode) => {
		return new Promise((resolve, reject) => {
			request.post('get-erp-client', {erp_client_code: clientCode}, (payload, status, request) => {
				if(status === 200 && payload && payload.response) {
					resolve(payload.response);
				}
				else {
					reject(request);
				}
			});
			
		});
	},
	listClients: (params = {}) => {
		const defaultParams = {
			page: 1,
			per_page: 10,
		}
		return new Promise((resolve, reject) => {
			request.post('list-clients', {...defaultParams, ...params}, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve({
						clients: payload,
						pagination: request.data.paginate,
					});
				}
				else {
					reject(request);
				}
			});
			
		});
	},
	deleteClient: (clientID) => {
		return new Promise((resolve, reject) => {
			request.post('delete-client', {client_code: clientID}, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
			
		});
	},
	// Group
	listGroups: (params = {}) => {
		const defaultParams = {
			page: 1,
			per_page: 10,
		}

		return new Promise((resolve, reject) => {
			request.post('list-groups', {...defaultParams, ...params}, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve({
						groups: payload,
						pagination: request.data.paginate,
					});
				}
				else {
					reject(request);
				}
			});
			
		});
	},
	getGroup: (groupID) => {
		return new Promise((resolve, reject) => {
			request.post('get-group', {store_code: groupID}, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
			
		});
	},
	deleteGroup: (groupID) => {
		return new Promise((resolve, reject) => {
			request.post('delete-group', {store_code: groupID}, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
			
		});
	},
	createOrUpdateGroup: (payload) => {
		return new Promise((resolve, reject) => {
			request.post('create-or-update-group', payload, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
			
		});
	},
	// Account
	getAccount: (accountCode) => {
		return new Promise((resolve, reject) => {
			request.post('get-account', {company_account_code: accountCode}, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
			
		});
	},
	listAccounts: (params = {}) => {
		const defaultParams = {
			page: 1,
			per_page: 10,
		}

		return new Promise((resolve, reject) => {
			request.post('list-accounts', {...defaultParams, ...params}, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve({
						accounts: payload,
						pagination: request.data.paginate,
					});
				}
				else {
					reject(request);
				}
			});
			
		});
	},
	deleteAccount: (accountID) => {
		return new Promise((resolve, reject) => {
			request.post('delete-account', {company_account_code: accountID}, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	createOrUpdateAccount: (payload) => {
		return new Promise((resolve, reject) => {
			request.post('create-or-update-account', payload, (payload, status, request) => {
				if(status === 200 && request.data.status === 'success') {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	matchAccountAndGroup: (groupID, accountID, setDefault = false) => {
		return new Promise((resolve, reject) => {
			request.post('match-account-and-group', {
				store_code: groupID,
				company_account_code: accountID,
				default: setDefault ? '1' : '0',
			}, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
			
		});
	},
	unmatchAccountAndGroup: (groupID, accountID) => {
		return new Promise((resolve, reject) => {
			request.post('unmatch-account-and-group', {store_code: groupID, company_account_code: accountID}, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
			
		});
	},
	// Exchange Type
	listExchangeTypes: (params) => {
		return new Promise((resolve, reject) => {
			request.post('list-currency-exchanges', params, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Payment Type
	listPaymentTypes: (params) => {
		const defaultParams = {
			page: 1,
			per_page: 10,
		}

		return new Promise((resolve, reject) => {
			request.post('list-company-payment-types', {...defaultParams, ...params}, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve({
						paymentTypes: payload,
						pagination: request.data.paginate,
					});
				}
				else {
					reject(request);
				}
			});
		});
	},
	getPaymentType: (typeID) => {
		return new Promise((resolve, reject) => {
			request.post('get-payment-type', {payment_type_id: typeID}, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getCompanyPaymentType: (typeID) => {
		return new Promise((resolve, reject) => {
			request.post('get-company-payment-type', {company_payment_type_id: typeID}, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	deletePaymentType: (paymentID) => {
		return new Promise((resolve, reject) => {
			request.post('delete-company-payment-type', {company_payment_type_id: paymentID}, (payload, status, request) => {
				if(status === 200) {
					resolve(true);
				}
				else {
					reject(request);
				}
			});
		});
	},
	createOrUpdatePaymentType: (payload) => {
		return new Promise((resolve, reject) => {
			request.post('create-or-update-company-payment-type', payload, (payload, status, request) => {
				if(status === 200) {
					resolve(true);
				}
				else {
					reject(request);
				}
			});
		});
	},
	reorderPaymentTypes: (payload, groupID = false) => {
		return new Promise((resolve, reject) => {
			let payloadParams = {rank_object: payload};

			if(groupID) {
				payloadParams.store_code = groupID;
			}

			request.post('rank-update-company-payment-types', payloadParams, (payload, status, request) => {
				if(status === 200) {
					resolve(true);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Installment
	listPaymentInstallments: (params) => {
		const defaultParams = {
			page: 1,
			per_page: 10,
		}

		return new Promise((resolve, reject) => {
			request.post('list-company-payment-installments', {...defaultParams, ...params}, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve({
						installments: payload,
						pagination: request.data.paginate,
					});
				}
				else {
					reject(request);
				}
			});
		});
	},
	getPaymentInstallment: (installmentID) => {
		return new Promise((resolve, reject) => {
			request.post('get-company-payment-installment', {installment_id: installmentID}, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	createOrUpdatePaymentInstallment: (payload) => {
		return new Promise((resolve, reject) => {
			request.post('create-or-update-company-payment-installment', payload, (payload, status, request) => {
				if(status === 200) {
					resolve(true);
				}
				else {
					reject(request);
				}
			});
		});
	},
	deletePaymentInstallment: (paymentTypeID, installmentID) => {
		return new Promise((resolve, reject) => {
			request.post('delete-company-payment-installment', { company_payment_type_id: paymentTypeID, installment_id: installmentID }, (payload, status, request) => {
				if(status === 200) {
					resolve(true);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Balance Reports
	listBalanceStatusReports: () => {
		return new Promise((resolve, reject) => {
			request.post('list-balance-status-reports', false, (payload, status, request) => {
				if (status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	listBalanceReports: (priceTypeID) => {
		return new Promise((resolve, reject) => {
			request.post('list-balance-reports', {price_type_id: priceTypeID}, (payload, status, request) => {
				if (status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// SMS
	listSMSProviders: () => {
		return new Promise((resolve, reject) => {
			request.post('list-sms-providers', false, (payload, status, request) => {
				if (status === 200 && payload) {
					resolve(payload.map((provider) => ({
						...provider,
						value: provider.id,
						label: provider.name,
					})));
				}
				else {
					reject(request);
				}
			});
		});
	},
	sendSmsCode: (type, params) => {
		return new Promise((resolve, reject) => {
			request.post('send-sms-code', { process_type: type, ...params }, (payload, status, request) => {
				if (status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	confirmSmsCode: (code, token, payload = {}) => {
		return new Promise((resolve, reject) => {
			request.post('confirm-sms-code', { sms_code: code, sms_token: token, ...payload }, (payload, status, request) => {
				if (status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	removeCard: (params) => {
		return new Promise((resolve, reject) => {
			request.post('confirm-sms-code', params, (payload, status, request) => {
				if (status === 200 && payload && payload.cards) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// URL Shorteners
	listURLShortenerProviders: () => {
		return new Promise((resolve, reject) => {
			request.post('list-shortener-providers', false, (payload, status, request) => {
				if (status === 200 && payload) {
					resolve(payload.map((provider) => ({
						...provider,
						value: provider.id,
						label: provider.name,
					})));
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Announcements
	createAnnouncement: (title, description, image) => {
		return new Promise((resolve, reject) => {
			request.post('express-announcement', {title: title, description: description, image: image, process_type: 'create'}, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	updateAnnouncement: (id, title, description, image) => {
		return new Promise((resolve, reject) => {
			request.post('express-announcement', {title: title, description: description, image: image, process_type: 'update', announcement_id: id}, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	deleteAnnouncement: (id) => {
		return new Promise((resolve, reject) => {
			request.post('express-announcement', {process_type: 'delete', announcement_id: id}, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getAnnouncements: () => {
		return new Promise((resolve, reject) => {
			request.post('express-announcement', {process_type: 'list'}, (payload, status, request) => {
				if(status === 200) {
					store.dispatch(setAnnouncements(payload));
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
}

export default companyServices;