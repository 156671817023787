module.exports = {
	pages: {
		home: {
			path: "/",
			component: "Intro",
			exact: true,
			linkTitle: "Intro",
			title: "Giriş",
			descriptiopn: "Ödedim yönetim ekranı",
			hidePageWrapper: true,
			requiresLogin: false, // Bu False, çünkü login durumuna göre sadece redirect ediyoruz.
			// meta: {
			// 	keywords: "Event, Pay, Ödeme"
			// },
			// Bu şekilde head kısmına custom meta da eklenebilir.
			// head: [
			// 	{
			// 		key: "meta",
			// 		content: false,
			// 		props: {
			// 			name: "description",
			// 			content: "Event Pay Pro..."
			// 		}
			// 	},
			// 	{
			// 		key: "description",
			// 		content: "Event Pay Pro..",
			// 		props: {}
			// 	}
			// ],
		},
		login: {
			path: '/login',
			component: "Login",
			exact: true,
			linkTitle: "Giriş",
			title: "Yönetici Girişi",
			hidePageWrapper: true,
			requiresLogin: false,
		},
		register: {
			path: '/register',
			component: "RegisterV1",
			exact: true,
			linkTitle: "Kayıt",
			title: "Şirket Kaydı",
			hidePageWrapper: true,
			requiresLogin: false,
		},
		registerV2: {
			path: '/register-v2',
			component: "Register",
			exact: true,
			linkTitle: "Kayıt",
			title: "Şirket Kaydı",
			hidePageWrapper: true,
			requiresLogin: false,
		},
		dashboard: {
			path: '/dashboard',
			component: "Dashboard",
			exact: true,
			linkTitle: "Dashboard",
			title: "Dashboard",
			requiresLogin: true,
		},
		payments: {
			path: '/payments/:query?',
			component: "Payments",
			exact: true,
			linkTitle: "Ödemeler",
			title: "Ödemeler",
			requiresLogin: true,
		},
		products: {
			path: '/products/:query?',
			component: "Products",
			exact: true,
			linkTitle: "Ürünler",
			title: "Ürünler",
			requiresLogin: true,
		},
		productDetail: {
			path: '/products/detail/:id',
			component: "ProductDetail",
			exact: true,
			linkTitle: "Ürün Detayı",
			title: "Ürün Detayı",
			requiresLogin: true,
		},
		clients: {
			path: '/clients/:query?',
			component: "Clients",
			exact: true,
			linkTitle: "Müşteriler",
			title: "Müşteriler",
			requiresLogin: true,
		},
		clientDetail: {
			path: '/clients/detail/:id',
			component: "ClientDetail",
			exact: true,
			linkTitle: "Müşteri Detayı",
			title: "Müşteri Detayı",
			requiresLogin: true,
		},
		orders: {
			path: '/orders/:query?',
			component: "Orders",
			exact: true,
			linkTitle: "Ödeme Talepleri",
			title: "Ödeme Talepleri",
			requiresLogin: true,
		},
		orderDetail: {
			path: '/orders/detail/:id',
			component: "OrderDetail",
			exact: true,
			linkTitle: "Talep Detayı",
			title: "Talep Detayı",
			requiresLogin: true,
		},
		commerceOrders: {
			path: '/commerce-orders/:query?',
			component: "CommerceOrders",
			exact: true,
			linkTitle: "Siparişler",
			title: "Siparişler",
			requiresLogin: true,
		},
		commerceOrderDetail: {
			path: '/commerce-orders/detail/:id',
			component: "CommerceOrderDetail",
			exact: true,
			linkTitle: "Sipariş Detayı",
			title: "Sipariş Detayı",
			requiresLogin: true,
		},
		orderPlans: {
			path: '/order-plans/:query?',
			component: "OrderPlans",
			exact: true,
			linkTitle: "Ödeme Planları",
			title: "Ödeme Planları",
			requiresLogin: true,
		},
		orderPlanDetail: {
			path: '/order-plans/detail/:id',
			component: "OrderPlanDetail",
			exact: true,
			linkTitle: "Ödeme Plan Detayı",
			title: "Ödeme Plan Detayı",
			requiresLogin: true,
		},
		orderTemplates: {
			path: '/order-templates/:query?',
			component: "OrderTemplates",
			exact: true,
			linkTitle: "Hazır Ödeme Şablonları",
			title: "Hazır Ödeme Şablonları",
			requiresLogin: true,
		},
		orderTemplateDetail: {
			path: '/order-templates/detail/:id',
			component: "OrderTemplateDetail",
			exact: true,
			linkTitle: "Hazır Ödeme Şablonu Detayı",
			title: "Hazır Ödeme Şablonu Detayı",
			requiresLogin: true,
		},
		paymentHandler: {
			path: '/paymenthandler/:source/:orderID/:from?',
			component: "PaymentHandler",
			exact: true,
			linkTitle: "Ödeme",
			title: "Ödeme",
			requiresLogin: true,
			hidePageWrapper: true,
		},
		notFound: {
			path: false,
			component: "NotFound",
			exact: false,
			linkTitle: "404",
			title: "Sayfa bulunamadı",
			status: 404,
			requiresLogin: false,
		},
		error: {
			path: false,
			component: "ErrorPage",
			exact: false,
			linkTitle: "Bir hata ile karşılaşıldı",
			title: "Bir hata ile karşılaşıldı",
			status: 500,
			requiresLogin: false,
		},
	},
	settings: {
		companyInfo: {
			path: '/settings/company-info',
			component: "CompanyInfo",
			exact: true,
			linkTitle: "Firma Bilgileri",
			title: "Firma Bilgileri",
			requiresLogin: true,
			requiresOwnership: true,
		},
		companyPaymentTypes: {
			path: '/settings/company-payment-types',
			component: "CompanyPaymentTypes",
			exact: true,
			linkTitle: "Ödeme Bilgileri",
			title: "Ödeme Bilgileri",
			requiresLogin: true,
			requiresOwnership: true,
		},
		companyPaymentTypeDetail: {
			path: '/settings/company-payment-types/:id',
			component: "CompanyPaymentTypeDetail",
			exact: true,
			linkTitle: "Ödeme Yöntemi Bilgileri",
			title: "Ödeme Yöntemi Bilgileri",
			requiresLogin: true,
			requiresOwnership: true,
		},
		groups: {
			path: '/settings/groups/:query?',
			component: "Groups",
			exact: true,
			linkTitle: "Gruplar",
			title: "Gruplar",
			requiresLogin: true,
			requiresOwnership: true,
		},
		groupDetail: {
			path: '/settings/groups/detail/:id',
			component: "GroupDetail",
			exact: true,
			linkTitle: "Grup Detayı",
			title: "Grup Detayı",
			requiresLogin: true,
			requiresOwnership: true,
		},
		accounts: {
			path: '/settings/accounts/:query?',
			component: "Accounts",
			exact: true,
			linkTitle: "Kullanıcılar",
			title: "Kullanıcılar",
			requiresLogin: true,
			requiresOwnership: true,
		},
		accountDetail: {
			path: '/settings/accounts/detail/:id',
			component: "AccountDetail",
			exact: true,
			linkTitle: "Kullanıcı Detayı",
			title: "Kullanıcı Detayı",
			requiresLogin: true,
			requiresOwnership: true,
		},
		costReports: {
			path: '/settings/cost-reports/:query?',
			component: "CostReports",
			exact: true,
			linkTitle: "Kullanım Raporları",
			title: "Kullanım Raporları",
			requiresLogin: true,
			requiresOwnership: true,
		},
		balanceReports: {
			path: '/settings/balance-reports',
			component: "BalanceReports",
			exact: true,
			linkTitle: "Bakiye Özeti",
			title: "Bakiye Özeti",
			requiresLogin: true,
			requiresOwnership: true,
		},
		productSettings: {
			path: '/settings/product-settings',
			component: "ProductSettings",
			exact: true,
			linkTitle: "Ürün Entegrasyon Ayarları",
			title: "Ürün Entegrasyon Ayarları",
			requiresLogin: true,
			requiresOwnership: true,
		},
		announcements: {
			path: '/settings/announcements',
			component: "Announcements",
			exact: true,
			linkTitle: "Duyurular",
			title: "Duyurular",
			requiresLogin: true,
			requiresOwnership: true,
		},
	}
}