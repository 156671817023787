import React, { useState, useEffect, /*useRef*/ } from 'react'

// Deps
import store from 'store'
import { connect } from 'react-redux';
import { setIndex, setActiveLanguage } from 'store/translation/actions'
import { setError } from 'controllers/navigator'
import parse from 'html-react-parser'

// Services
import translationServices from 'services/translation'

export const getLanguageData = (lang) => {
	store.dispatch(setActiveLanguage(lang));
	localStorage.setItem('activeLanguage', lang);
	document.body.setAttribute('lang', lang);

	translationServices.fetchLanguage(lang).then((payload) => {
		store.dispatch(setIndex(payload));
	}).catch(() => {
		setError();
		getLanguageData('tr');
	})
}

const mapStateToProps = (state, ownProps) => {
	return {
		translationIndex: state.translation.index,
	};
};

const getStr = (text, translationIndex) => {
	let keyMap = text.toString().split('.');

	let obj = { ...translationIndex };

	for (const key of keyMap) {
		obj = obj[key];
		if (!obj && obj !== '') {
			// console.warn('Translation key not found!:', text);
			return false;
		}
	}
	return obj;
}

export const getString = (text) => {
	const translationIndex = store.getState()?.translation?.index;
	return getStr(text, translationIndex)
}

const PlainStr = React.memo(({text, translationIndex, params, ...props}) => {
	if(translationIndex === false) {
		return false;
	}
	else {
		const [str, setStr] = useState('');
		// const innerParams = useRef(params);

		useEffect(() => {
			let newStr = getStr(text, translationIndex);
			
			if(params) {
				for(let k = 0; k < params.length; k++) {
					if(newStr) {
						let appendStr = getStr(params[k], translationIndex);
						if(appendStr === false) { appendStr = params[k] }
						newStr = newStr.replace(`{${k}}`, appendStr);
					}
				}
			}
			setStr(newStr ? parse(newStr) : false);


		}, [translationIndex, text, params])

		return str;
	}
})

export const T = connect(mapStateToProps)(PlainStr);