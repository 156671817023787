const divanConfig = {
	key: 'divangrup',
	logo: '/assets/images/company-logos/divan.svg',
};

const siparisgelecekConfig = {
	key: 'siparisgelecek',
	logo: '/assets/images/company-logos/siparisgelecek.png',
};

const toyzzshopConfig = {
	key: 'toyzzshop',
	theme: '2',
	logo: '/assets/images/company-logos/toyzzshop.png',
	defaultLoginEmailDomain: 'toyzzshop.com',
	// base Config
	baseConfig: {
		currencyDisplayMode: 'name',
		currencyDisplayPosition: 'end',
		maps: {
			apiKey: 'AIzaSyDrR_pXlUq_qCymZfm7aanRCaqAO7rOwFg',
		},
	}
};

const domainCompanyIndex = {
	// Divan
	'divangrup.odedim.com': divanConfig,
	'divangrup.pstv.co': divanConfig,
	// Siparis Gelecek
	'b2b.siparisgelecek.com': siparisgelecekConfig,
	'siparisgelecek.pstv.co': siparisgelecekConfig,
	// Toyzzshop
	'www.toyzzsmart.com': toyzzshopConfig,
	'toyzzsmart.com': toyzzshopConfig,
	'ode.toyzzsmart.com': toyzzshopConfig,
	'instore.toyzzshop.com': toyzzshopConfig,
	'instore.toyzzshop.net': toyzzshopConfig,
	'toyzzshop-instore.pstv.co': toyzzshopConfig,
	// Local Dev
	'localhost:3004': toyzzshopConfig,
	
}

export default domainCompanyIndex;
