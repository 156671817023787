import React from 'react'

const Loader = (props) => {
	return (
		<div className="section loader">
			<div className="loader-content">
				<svg
					className="loader-logo"
					version="1.1"
					id="Layer_1"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					x="0px"
					y="0px"
					viewBox="0 0 59 45"
					style={{enableBackground: 'new 0 0 59 45'}}
					xmlSpace="preserve">
					<g>
						<g>
							<g>
								<path
									className="st0"
									style={{
										fillRule: 'evenodd',
										clipRule: 'evenodd',
										fill: '#FFF',
									}}
									d="M3,22.7L26.6,1.8c3.7-3.3,9.2-2.7,12.3,1.3l2.8,3.6c3.1,4,2.5,9.9-1.2,13.2L16.9,40.7
									c-3.7,3.3-9.2,2.7-12.3-1.3l-2.8-3.6C-1.3,31.9-0.8,26,3,22.7z M18.3,24.9L41.9,4c3.7-3.3,9.2-2.7,12.3,1.3L57,8.9
									c3.1,4,2.5,9.9-1.2,13.2L32.2,42.9c-3.7,3.3-9.2,2.7-12.3-1.3L17.1,38C14,34.1,14.6,28.2,18.3,24.9z"/>
							</g>
						</g>
					</g>
				</svg>
				<p className="loader-message">{props.text}</p>
			</div>
		</div>
	)
}

Loader.defaultProps = {
	text: 'Yükleniyor'
}

export default Loader