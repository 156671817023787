import React from 'react'

const ErrorPage = () => {
	return (
		<main className="page error">
			<h1>Bir Hata İle Karşılaşıldı</h1>
			<p>Lütfen tekrar deneyin.</p>
		</main>
	)
}

export default ErrorPage