import React from 'react';

// Deps
import OptsModal from 'views/shared/modals/options'

const AlertModal = ({ onConfirm, closeText, ...props}) => {
	return (
		<OptsModal
			{...props}
			opts={[
				{
					className: 'confirm-yes',
					text: closeText,
				},
		]} />
	)
}

AlertModal.defaultProps = {
	name: 'alert',
	containerClass: 'modal-options alert',
	closeText: 'Tamam',
}

export default AlertModal