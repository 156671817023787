// Deps
import request from 'utils/request'
// import store from 'store'
// import config from 'config'

// Actions
// import {
// 	setBasketContent,
// } from "store/commerce/actions";

const translationServices = {
	fetchLanguage: (language) => {
		return new Promise((resolve, reject) => {
			request.get(
				`/translations/${language}.json`,
				false,
				(payload, status, request) => {
					if(status >= 200 && status <= 200) {
						resolve(request.data);
					}
					else {
						reject();
					}
				},
				{
					external: true
				}
			)
		});
	},
}

export default translationServices;