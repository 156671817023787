// Data
import domainCompanyIndex from 'config/domains'

// Services
import userServices from 'services/user'

// Static Assets
import Logo from 'assets/images/shared/logo-light.svg'

const getCompanyConfig = () => {
	const subdomain = window.location.host;
	const config =  domainCompanyIndex[subdomain] ? domainCompanyIndex[subdomain] : {
		logo: Logo,
		customLogo: false,
		theme: '1'
	}

	userServices.setTheme(config.theme);

	return config;
}

export default getCompanyConfig;