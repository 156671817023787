import React, { useState, useEffect, useRef } from 'react'

// Deps
import omit from 'lodash/omit'

// Partials
import Link from 'views/shared/partials/link'

const useLoadingStatus = (loading) => {
	let loadingTimeout = useRef(false);

	const [active, setActive] = useState(loading);
	const [show, setShow] = useState(loading);

	useEffect(() => {
		if(loadingTimeout.current !== false){
			clearTimeout(loadingTimeout.current);
			loadingTimeout.current = false;
		}

		if(loading){
			setActive(true);
			loadingTimeout.current = setTimeout(function() {
				setShow(true);
			}, 30);
		}
		else {
			setShow(false);
			loadingTimeout.current = setTimeout(function() {
				setActive(false);
			}, 200);
		}
	}, [loading]);

	return [active, show];
}

const Btn = React.forwardRef((props, ref) => {
	let btnProps = omit(props, [
		'className', 'wrapText', 'tag', 'icon', 'afterIcon', 'loading', 'status',
		'small', 'text-small', 'big', 'outline', 'underline', 'noHover', 'block', 'rounded', 'wide', 'narrow', 'low', 'plain',
		'green', 'blue', 'nude', 'black', 'white', 'gray', 'error', 'success', 'facebook',
	]);
	let Tag = props.tag;

	const [status, setStatus] = useState(props.loading ? 'loading' : props.status);
	const [statusActive, statusShow] = useLoadingStatus(status);

	useEffect(() => {
		setStatus(props.loading ? 'loading' : props.status);
	}, [props.status, props.loading]);

	btnProps.className = 'btn ' + props.className;

	if(props.tag === "button"){
		btnProps.type = props.type;
	}
	else if(props.tag === 'link') {
		Tag = Link;
	}
	if(props.noHover) {
		btnProps.className += " no-hover";
	}
	if(props.outline) {
		btnProps.className += " outline";
	}
	if(props.small) {
		btnProps.className += " small";
	}
	if(props.big) {
		btnProps.className += " big";
	}
	if(props.block) {
		btnProps.className += " block";
	}
	if(props.rounded) {
		btnProps.className += " rounded";
	}
	if(props.wide) {
		btnProps.className += " wide";
	}
	if(props.narrow) {
		btnProps.className += " narrow";
	}
	if(props.low) {
		btnProps.className += " low";
	}
	if(props['text-small']) {
		btnProps.className += " text-small";
	}
	if(props.underline) {
		btnProps.className += " underline";
	}
	if(props.outline) {
		btnProps.className += " outline";
	}
	if(props.plain) {
		btnProps.className += " plain";
	}

	// Colors
	if(props.green) {
		btnProps.className += " green";
	}
	if(props.blue) {
		btnProps.className += " blue";
	}
	if(props.nude) {
		btnProps.className += " nude";
	}
	if(props.black) {
		btnProps.className += " black";
	}
	if(props.white) {
		btnProps.className += " white";
	}
	if(props.gray) {
		btnProps.className += " gray";
	}
	if(props.error) {
		btnProps.className += " error";
	}
	if(props.success) {
		btnProps.className += " success";
	}
	if(props.facebook) {
		btnProps.className += " facebook";
	}

	// Misc
	if(ref) {
		btnProps.ref = ref;
	}

	// Status
	if(statusShow){ btnProps.className += ' show-status'; }
	
	if(status){ btnProps.className += ' status-' + status; }

	var WrapElem = props.wrapText ? 'span' : React.Fragment;
	var wrapProps = {};

	if(props.wrapText) {
		wrapProps.className = "btn-text";
	}

	return (
		<Tag {...btnProps}>
			{(statusActive) && 
				<span className="btn-statuswrap">
					{status === 'complete' ?
						<i className={"btn-status icon-check"}></i>
						:
						<React.Fragment>
							{status === 'loading' ? 
								<i className={"btn-status icon-spinner"}></i>
								:
								<i className={"btn-status icon-"+status}></i>
							}
						</React.Fragment>
					}
				</span>
			}
			<div className="btn-content">
				{props.icon &&
					<i className={'btn-icon icon-' + props.icon + ((props.children || props.afterIcon) ? ' pre' : '')}></i>
				}
				<WrapElem {...wrapProps}>
					{props.children}
				</WrapElem>
				{props.afterIcon &&
					<i className={'btn-icon post icon-' + props.afterIcon}></i>
				}
			</div>
		</Tag>
	)
})

Btn.defaultProps = {
	className: '',
	tag: 'button',
	status: false,
	wrapText: false,
	icon: false,
	afterIcon: false,
	type: "button",
	noHover: false,
	outline: false,
	small: false,
}

export default Btn