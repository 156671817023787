// Services
// import userServices from 'services/user'

const parseSessionData = () => {
	let defaultData = {
		initialized: false,
		data: null,
		accessToken: false,
		refreshToken: false,
		accountCode: false,
		groups: false,
	}

	let data = defaultData;
	let storageData = false;

	if(localStorage.getItem('userData') !== null){
		try {
			storageData = JSON.parse(localStorage.getItem('userData'));

			if(storageData.company_account_code){
				data.accountCode = storageData.company_account_code;

				// userServices.setTheme((storageData.company && storageData.company.theme_id) ? storageData.company.theme_id.toString() : '1');
			}
		}
		catch(e) {
			console.warn('Kullanıcı verisi işlenemedi, temizleniyor', e);
			localStorage.removeItem('userData');
		}
	}

	if(localStorage.getItem('refreshToken') !== null){
		data.refreshToken = localStorage.getItem('refreshToken');
	}

	if(localStorage.getItem('accessToken') !== null){
		data.accessToken = localStorage.getItem('accessToken');

		// For Faster Logins (?)
		if(storageData) {
			data.data = storageData;
		}
	}

	return data;
};

const initialState = {
	// data, authToken, guestToken
	...parseSessionData(),
};

const siteReducer = (state = initialState, action) => {
	if (action.type === "SET_DATA") {
		return {
			...state,
			data: action.payload
		};
	}
	else if (action.type === "SET_ACCOUNT_CODE") {
		return {
			...state,
			accountCode: action.payload
		};
	}
	else if (action.type === "SET_INITIALIZED") {
		return {
			...state,
			initialized: action.payload
		};
	}
	else if (action.type === "SET_ACCESS_TOKEN") {
		return {
			...state,
			accessToken: action.payload
		};
	}
	else if (action.type === "SET_REFRESH_TOKEN") {
		return {
			...state,
			refreshToken: action.payload
		};
	}
	else if (action.type === "SET_GROUPS") {
		return {
			...state,
			groups: action.payload
		};
	}

	return state;
};

export default siteReducer;