import React, {useState, /*useEffect*/} from 'react'

// Deps
import parseHTML from 'functions/parse-html';

// Partials
import Loader from 'views/shared/partials/loader'

const TextModal = (props) => {
	const [title/*, setTitle*/] = useState(props.title);
	const [content/*, setContent*/] = useState(props.content ? parseHTML(props.content) : false);

	return (
		<div className={props.className}>
			{props.closeBtn}
			<div className="modal-content loader-container">
				<Loader loading={!content && !title} />
				{title && <h1 className="text-title">{title}</h1>}
				{content &&
					<div className="text-content wysiwyg highlight-links">
						{content}
					</div>
				}
			</div>
		</div>
	)
}

TextModal.defaultProps = {
	className: "",
	containerClass: "modal-text",
	name: "text",
	title: false,
	content: false,
	source: false,
}

export default TextModal