import React from 'react';

// Deps
import 'assets/scss/app.scss';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
//import * as serviceWorker from './serviceWorker';

import { Router, Switch, Route } from 'react-router-dom'
import store from './store/'
import { Provider } from 'react-redux'

// Controllers
import DomWatcher from 'controllers/dom-watcher'
import ErrorLogger from 'controllers/error-logger'

// Utilities
import history from 'utils/history.js'

// Partials
import AsyncComponent from 'views/shared/partials/async-component'

// Pages
import ErrorPage from 'views/shared/pages/error'

// Polyfills
require('es6-promise/auto');

// Entries
const AsyncTerminal = AsyncComponent(() => { return import ('route-bases/terminal'); })
const AsyncPayment = AsyncComponent(() => { return import ('route-bases/payment'); })
const AsyncAdmin = AsyncComponent(() => { return import ('route-bases/admin'); })
// const AsyncAppView = AsyncComponent(() => { return import ('route-bases/appview'); })

window.$odedim = {
	retinaDevice: (window.devicePixelRatio > 1),
};

ReactDOM.render(
	<Sentry.ErrorBoundary fallback={ErrorPage}>
		<Provider store={store}>
			<DomWatcher />
			<ErrorLogger />
			<Router history={history}>
				<Switch>
					{/*<Route path="/(appview|appview/*)" component={AsyncAppView} />*/}
					<Route path="/(terminal|terminal/*)" component={AsyncTerminal} />
					<Route path="/(pay|pay/*|company|company/*)" component={AsyncPayment} />
					<Route component={AsyncAdmin} />
				</Switch>
			</Router>
		</Provider>
	</Sentry.ErrorBoundary>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();