const events =  {
	callbacks: {},
	trigger: (eventName, data = null) => {
		if (events.callbacks[eventName]) {
			for(const callback of events.callbacks[eventName]) {
				callback(data);
			}
		}
	},
	bind: (eventName, callback) => {
		if(!events.callbacks[eventName]) {
			events.callbacks[eventName] = [];
		}
		events.callbacks[eventName].push(callback);
	},
	unbind: (eventName, callback) => {
		if(events.callbacks[eventName]) {
			let delIndex = events.callbacks[eventName].indexOf(callback);
			if(delIndex > -1) {
				delete events.callbacks[eventName][delIndex];
			}
		}
	},
};

export default events;