module.exports = {
	pages: {
		home: {
			path: "/terminal/",
			component: "Intro",
			exact: true,
			linkTitle: "Intro",
			title: "Giriş",
			descriptiopn: "Ödedim yönetim ekranı",
			requiresLogin: false, // Bu False, çünkü login durumuna göre sadece redirect ediyoruz.
			// meta: {
			// 	keywords: "Event, Pay, Ödeme"
			// },
			// Bu şekilde head kısmına custom meta da eklenebilir.
			// head: [
			// 	{
			// 		key: "meta",
			// 		content: false,
			// 		props: {
			// 			name: "description",
			// 			content: "Event Pay Pro..."
			// 		}
			// 	},
			// 	{
			// 		key: "description",
			// 		content: "Event Pay Pro..",
			// 		props: {}
			// 	}
			// ],
		},
		login: {
			path: '/terminal/login',
			component: "Login",
			exact: true,
			linkTitle: "Giriş",
			title: "Satıcı Girişi",
			requiresLogin: false,
		},
		dashboard: {
			path: '/terminal/dashboard',
			component: "Dashboard",
			exact: true,
			linkTitle: "Terminal",
			title: "Terminal Ekranı",
			requiresLogin: true,
		},
		commerce: {
			path: '/terminal/commerce',
			component: "Commerce",
			exact: true,
			linkTitle: "Ürünler",
			title: "Ürünler",
			requiresLogin: true,
		},
		orderDetail: {
			path: '/terminal/orders/detail/:id',
			component: "OrderDetail",
			exact: true,
			linkTitle: "Ödeme Bilgileri",
			title: "Ödeme Bilgileri",
			requiresLogin: true,
		},
		announcements: {
			path: '/terminal/announcements',
			component: "Announcements",
			exact: true,
			linkTitle: "Duyurular",
			title: "Duyurular",
			requiresLogin: true,
		},
		// clientDetail: {
		// 	path: '/terminal/clients/:id',
		// 	component: "ClientDetail",
		// 	exact: true,
		// 	linkTitle: "Müşteri Bilgileri",
		// 	title: "Müşteri Bilgileri",
		// 	requiresLogin: true,
		// },
		notFound: {
			path: false,
			component: "NotFound",
			exact: false,
			linkTitle: "404",
			title: "Sayfa bulunamadı",
			status: 404,
			requiresLogin: false,
		},
		error: {
			path: false,
			component: "ErrorPage",
			exact: false,
			linkTitle: "Bir hata ile karşılaşıldı",
			title: "Bir hata ile karşılaşıldı",
			status: 500,
			requiresLogin: false,
		},
	},
	commerce: {
		products: {
			path: '/terminal/commerce/products/:query?',
			component: "Products",
			exact: true,
			linkTitle: "Ürünler",
			title: "Ürünler",
			requiresLogin: true,
		},
		categories: {
			path: '/terminal/commerce/categories/:query?',
			component: "Categories",
			exact: true,
			linkTitle: "Kategoriler",
			title: "Kategoriler",
			requiresLogin: true,
		},
		categoryDetail: {
			path: '/terminal/commerce/category/:category/:query?',
			component: "CategoryDetail",
			exact: true,
			linkTitle: "Kategori Ürünleri",
			title: "Kategori Ürünleri",
			requiresLogin: true,
		},
		brands: {
			path: '/terminal/commerce/brands/:query?',
			component: "Brands",
			exact: true,
			linkTitle: "Markalar",
			title: "Markalar",
			requiresLogin: true,
		},
		brand: {
			path: '/terminal/commerce/brand/:brand/:query?',
			component: "BrandDetail",
			exact: true,
			linkTitle: "Marka Ürünleri",
			title: "Marka Ürünleri",
			requiresLogin: true,
		},
		orders: {
			path: '/terminal/commerce/orders/:query?',
			component: "Orders",
			exact: true,
			linkTitle: "Siparişler",
			title: "Siparişler",
			requiresLogin: true,
		},
		storeOrders: {
			path: '/terminal/commerce/store-orders/:query?',
			component: "StoreOrders",
			exact: true,
			linkTitle: "Mağaza Siparişleri",
			title: "Mağaza Siparişleri",
			requiresLogin: true,
		},
		basket: {
			path: '/terminal/commerce/basket',
			component: "Basket",
			exact: true,
			linkTitle: "Sepet",
			title: "Sepet",
			requiresLogin: true,
		},
		checkout: {
			path: '/terminal/commerce/checkout',
			component: "Checkout",
			exact: true,
			linkTitle: "Sipariş Oluştur",
			title: "Sipariş Oluştur",
			requiresLogin: true,
		},
	}
}