const objectToQuery = (object) => {
	let serialized = [];

	for (let k = 0; k < Object.keys(object).length; k++) {
		let key = Object.keys(object)[k];
		serialized.push(encodeURIComponent(key) + '=' + encodeURIComponent(object[key]));
	}
	return serialized.join('&');
}

export default objectToQuery