module.exports = {
	pages: {
		intro: {
			path: "/pay/",
			component: "Intro",
			exact: true,
			linkTitle: "Intro",
			title: "Bakiye Ödeme Sorgusu",
			description: "Ödedim yönetim ekranı",
			requiresLogin: false, // Bu False, çünkü login durumuna göre sadece redirect ediyoruz.
			// meta: {
			// 	keywords: "Event, Pay, Ödeme"
			// },
			// Bu şekilde head kısmına custom meta da eklenebilir.
			// head: [
			// 	{
			// 		key: "meta",
			// 		content: false,
			// 		props: {
			// 			name: "description",
			// 			content: "Event Pay Pro..."
			// 		}
			// 	},
			// 	{
			// 		key: "description",
			// 		content: "Event Pay Pro..",
			// 		props: {}
			// 	}
			// ],
		},
		barcodePayment: {
			path: "/pay/barcode/:token/",
			component: "BarcodePayment",
			exact: true,
			linkTitle: "Barkod ile Ödeme",
			title: "Barkod ile Ödeme",
			requiresLogin: false,
		},
		company: {
			path: "/company/:companyCode",
			component: "Company",
			exact: true,
			linkTitle: "Şirket Ekranı",
			title: "Şirket Ekranı",
			description: "Şirket ödeme yönetim ekranı.",
			requiresLogin: false, 
		},
		payment: {
			path: "/pay/:orderCode/",
			component: "Payment",
			exact: true,
			linkTitle: "Ödeme Ekranı",
			title: "Ödeme Ekranı",
			requiresLogin: false,
			multiLanguage: true,
		},
		notFound: {
			path: false,
			component: "NotFound",
			exact: false,
			linkTitle: "404",
			title: "Sayfa bulunamadı",
			status: 404,
			requiresLogin: false,
		},
		error: {
			path: false,
			component: "ErrorPage",
			exact: false,
			linkTitle: "Bir hata ile karşılaşıldı",
			title: "Bir hata ile karşılaşıldı",
			status: 500,
			requiresLogin: false,
		},
	}
}