import React from 'react'

//Deps
import { connect } from 'react-redux'
import config from 'config'
import debounce from 'lodash/debounce'
//import throttle from 'lodash/throttle'
import store from 'store'
import pxToRem from 'functions/px-to-rem'
import { setMobile, setWindowWidth, setWindowHeight } from 'store/site/actions'

const mapStateToProps = state => {
	return {
		mobile: state.site.mobile,
		scrollPos: state.site.scrollPos,
		windowWidth: state.site.windowWidth,
		windowHeight: state.site.windowHeight,
	};
};

class DomWatcher extends React.Component {
	constructor(props){
		super(props);

		this.resize = debounce(this.resize.bind(this), 50);
		//this.scroll = throttle(this.scroll.bind(this), 70);

		this.isMobile = props.mobile; //this.getMobileState();
		this.scrollPos = getScrollPos;
	}

	resize(){
		let newState = getMobileState();
		if(this.isMobile !== newState){
			window.isMobile = newState;
			this.isMobile = newState;
			store.dispatch(setMobile(newState));
		}
		if(this.props.windowWidth !== window.innerWidth) {
			store.dispatch(setWindowWidth(window.innerWidth));
		}
		if(this.props.windowHeight !== window.innerHeight) {
			store.dispatch(setWindowHeight(window.innerHeight));
		}
	}

	// scroll(){
	// 	let newState = getScrollPos();
	// 	if(this.scrollPos !== newState){
	// 		window.scrollPos = newState;
	// 		this.setState({scrollPos: newState});
	// 		store.dispatch(setScrollPos(newState));
	// 	}
	// }	

	componentDidMount(){
		let vm = this;
		window.addEventListener('resize', vm.resize);
		//window.addEventListener('scroll', vm.scroll);

		vm.resize();
		
		setTimeout(() => {
			vm.resize();
		}, 1000)
	}

	componentWillUnmount(){
		window.removeEventListener('resize', this.resize);
	}

	render() {
		return false;
	}
}

export default connect(mapStateToProps)(DomWatcher);

export const getMobileState = () => {
	if(window.innerWidth <= config.mobileBreakPoint){
		return true;
	}
	else{
		return false;
	}
}

export const getScrollPos = () => {
	return pxToRem(window.scrollY || window.pageYOffset);
}